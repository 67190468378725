<template>
  <section v-if="posts.length > 0" id="recomandari">
    <div class="home-articles">
      <div id="recomandationCarousel" class="blogCarousel">
        <Carousel :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="post in posts" :key="post.id" class="block-item blog_noutati">
            <div class="item_wrp carousel_slide">
              <div class="art-det">
                <div class="details">
                  <span> </span> <br />
                  <span>{{ dateformat(post.published_at) }}</span>
                </div>
                <div class="wrp_title">
                  <router-link :to="postlink(post.slug)"
                    ><span>{{ limitWord(post.title, 13) }}</span></router-link
                  >
                </div>
              </div>
              <div class="img_wrp">
                <router-link :to="postlink(post.slug)">
                  <img
                    v-if="post.featured_images[0]"
                    :src="post.featured_images[0].path"
                    alt="IMG"
                    class="img-responsive"
                    loading="lazy"
                  />
                  <img
                    data-src=""
                    src="../../../assets/images/no-image.png"
                    alt=""
                    class="img-responsive"
                    loading="lazy"
                  />
                </router-link>
              </div>
            </div>
          </Slide>

          <template #addons>
            <navigation v-if="windowWidth > 768">
              <template #next
                >Urmatoare
                <img
                  alt="Bloguri urmatoare"
                  width="75"
                  height="14"
                  src="@/assets/images/arrow-right-75.svg"
                />
              </template>
              <template #prev
                >Anterioare
                <img
                  alt="Bloguri anterioare"
                  width="75"
                  height="14"
                  src="@/assets/images/arrow-left-75.svg"
                />
              </template>
            </navigation>
          </template>
        </Carousel>
        <div class="title">
          <div class="more-btn--wrapper">
            <router-link
              class="more-articles arrow--wrapper position-relative"
              target="_blank"
              to="/blog-seminee"
            >
              <span class="more-video--desc">Mai multe articole</span>
              <span v-if="windowWidth >= 768" class="arrow--right"></span>
              <img v-if="windowWidth < 768" src="@/assets/images/arrow-right-gray.svg" width="165" height="14" alt="arrow-right">
            </router-link>
          </div>
        </div>

        <div id="arrow" class="col-12 col-sm-12 col-md-3 col-lg-2 arrows">
          <div class="custom-controls-nav cell"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { blogMixin } from "@/mixins/blogMixin";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "start",
      wrapAround: true,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 576px and up
      576: {
        itemsToShow: 2,
        snapAlign: "start",
      },
      // 768 and up
      768: {
        itemsToShow: 3,
        snapAlign: "start",
      },
      // 991 and up
      991: {
        itemsToShow: 3,
        snapAlign: "start",
      },
      // 1200 and up
      1200: {
        itemsToShow: 5,
        snapAlign: "start",
      },
      // 1400 and up
      1400: {
        itemsToShow: 6,
        snapAlign: "start",
      },
    },
  }),
  computed: {
    posts() {
      return this.$store.getters["blog/latestPosts"];
    },
  },
  methods: {
    limitWord(string, limit) {
      let new_string = "";
      let words_string = string.split(" ");
      if (limit <= words_string.length) {
        for (let i = 0; i < limit; i++) {
          new_string = new_string + words_string[i] + " ";
        }
        return new_string + "...";
      }
      return string;
    },
  },
  mounted() {
    this.$store.dispatch("blog/getLatestPosts");
  },
  mixins: [blogMixin],
});
</script>

<style>
#recomandari .carousel__prev,
#recomandari .carousel__next {
  top: unset;
  bottom: -94px;
  width: 80px;
  border-radius: 0;
  z-index: 1;
  background: unset;
  text-indent: -9000px;
}

#recomandari .carousel__prev {
  left: calc(50% - 190px);
}

#recomandari .carousel__next {
  right: calc(50% - 190px);
}

#recomandari .carousel__slide {
  padding: 10px;
}

#recomandari .home-articles .block-item .details {
  object-fit: contain;
  font-family: Montserrat Bold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #000000;
}

.carousel__next,
.carousel__prev {
  width: 50px !important;
}


@media (max-width: 768px) {
  #recomandari .home-articles {
    padding-top: 0;
    padding-bottom: 110px;
  }

  #recomandari .home-articles .block-item .details {
    font-size: 14px;
    margin-bottom: 8px;
  }

  #recomandari .home-articles .more-btn--wrapper {
    margin: 0;
    text-align: left;
  }

  #recomandari .more-btn--wrapper .arrow--wrapper img {
    width: 95px;
    margin-top: 7px;
    float: right;
  }
}
</style>
