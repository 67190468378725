<template>
    <section id="video_feed" class="home_video">

        <video-feed></video-feed>

    </section>
</template>

<script>
import VideoFeed from '@/components/blocks/video/VideoFeed.vue'

export default {
    components: {
        VideoFeed,
    }
}
</script>