<template>
  <section id="europemap">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h2 class="title">
            Prezenți în 28 de țări din Europa prin partenerii noștri
          </h2>
        </div>
      </div>
    </div>
    <img width="911" height="700" loading="lazy" src="./../../assets/images/map-home.webp" alt="Europe Map" />
  </section>
</template>

<style>
#europemap {
  position: relative;
	background-color: #000;
  overflow-x: clip;
  overflow-y: visible;  
}

#europemap h2.title {
	object-fit: contain;
	font-family: Raleway SemiBold;
	font-size: 72px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.03;
	letter-spacing: normal;
	color: #fff;
	padding: 80px 0;
}

#europemap img {
  width: auto;
	position: absolute;
  bottom: -80px;
  right: 0;
}

@media (max-width: 1900px) {
  #europemap h2.title {
    font-size: 58px;
    padding: 60px 0;
    padding-right: 100px;
  }
  #europemap img {
    height: 550px;
  }
}
@media (max-width: 1200px) {
  #europemap h2.title {
    font-size: 50px;
    padding-right: 70px;
  }
  #europemap img {
    height: 465px;
    bottom: -50px;
  }
}

@media (max-width: 991px) {
  #europemap h2.title {
    font-size: 44px;
    padding-right: 0;
  }
  #europemap img {
    height: 335px;
    right: -20px;
    bottom: -30px;
  }
}

@media (max-width: 768px) {
  #europemap {
    margin: 0;
    margin-bottom: 100px;
    height: 650px;
  }
  #europemap h2.title {
    font-size: 54px;
    width: 335px;
    padding-bottom: 0;
  }
  #europemap img {
    /* position: relative; */
    bottom: -55px;
    height: 360px;
    right: 60px;
  }
}

@media (max-width: 576px) {
  #europemap {
    height: 730px;    
    margin-bottom: 130px;
  }
  #europemap h2.title {
    margin: auto;
  }
  #europemap img {
    left: 30px;
    bottom: -90px;
    height: 490px;
  }
}

</style>
