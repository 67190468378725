<template>
    <section id="produse" class="homemaincategs">
        <div v-if="categories" class="container">
            <div class="row home-menu-wrp" v-if="isMobile()">
                <!-- <category-card v-for="n in 14" :key="n"></category-card> -->
                <category-card
                    v-for="(categ, index) in categories"
                    :key="index + categ.id"
                    :id="categ.id"
                    :name="categ.name"
                    :image="categ.mobile_image"
                    :slug="categ.slug"
                ></category-card>
            </div>
            <div class="row home-menu-wrp" v-else>
                <category-card
                    v-for="(categ, index) in categories"
                    :key="index + categ.id"
                    :id="categ.id"
                    :name="categ.name"
                    :image="categ.preview_image"
                    :slug="categ.slug"
                ></category-card>
            </div>
        </div>
    </section>
</template>

<script>
import CategoryCard from "@/components/blocks/category/CategoryCard.vue";

export default {
    name: "ShopByCategory",
    components: {
        CategoryCard,
    },
    computed: {
        categories() {
            return [
                {
                    id: 711,
                    name: "Seminee si Sobe pe lemne",
                    slug: "semineu-lemne-pe-colt-preturi-sobe_131",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/e2f/fcb/thumb_1027150_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/e2f/fcb/thumb_1027150_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/a2a/54f/thumb_463912_0_0_0_0_auto.webp",
                },
                {
                    id: 271,
                    name: "Seminee Decorative",
                    slug: "semineu-decorativ-fals-ieftin-living-apartament_271",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a06/e51/thumb_111_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a06/e51/thumb_111_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/a2a/550/thumb_463915_0_0_0_0_auto.webp",
                },
                {
                    id: 256,
                    name: "Centrale si Seminee pe peleti",
                    slug: "semineu-peleti-aer-cald-termosemineu-calorifere_256",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a06/fb4/thumb_112_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a06/fb4/thumb_112_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/536/1e5/thumb_463160_0_0_0_0_auto.webp",
                },
                {
                    id: 144,
                    name: "Materiale de constructii",
                    slug: "materiale-montaj-constructie-semineu-soba_144",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/611/be4/282/thumb_87417_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/611/be4/282/thumb_87417_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/536/1e9/thumb_463169_0_0_0_0_auto.webp",
                },
                {
                    id: 8,
                    name: "Cosuri de fum",
                    slug: "cosuri-de-fum-ieftine-horn-exterior_8",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/615/6d6/433/thumb_93907_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/615/6d6/433/thumb_93907_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/a2c/0f8/thumb_463921_0_0_0_0_auto.webp",
                },
                {
                    id: 462,
                    name: "Instalatii termice",
                    slug: "instalatii-termice-preturi-materiale-magazin-online_462",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/cf4/74b/thumb_1026841_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/cf4/74b/thumb_1026841_350_260_0_0_fit.webp",
                    icon:
                        null,
                },
                {
                    id: 9,
                    name: "Burlane seminee si sobe",
                    slug: "burlane-soba-semineu-centrala-lemne-tuburi-producator_9",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a07/7f5/thumb_115_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a07/7f5/thumb_115_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/b70/247/thumb_464065_0_0_0_0_auto.webp",
                },
                {
                    id: 134,
                    name: "Grile si piese de ventilatie",
                    slug: "componente-ventilatie-aer-cald-semineu_134",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a07/699/thumb_114_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a07/699/thumb_114_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/621/cd4/5f0/thumb_465222_0_0_0_0_auto.webp",
                },
                {
                    id: 465,
                    name: "Seminee pe gaz",
                    slug: "seminee-pe-gaz-focar-butelie_191",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/cf3/793/thumb_1026838_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/cf3/793/thumb_1026838_350_260_0_0_fit.webp",
                    icon: 
                        "https://www.pefoc.ro/storage/app/uploads/public/63e/cf3/798/thumb_1026840_0_0_0_0_auto.webp",
                },
                {
                    id: 108,
                    name: "Accesorii",
                    slug: "accesorii-intretinere-seminee-sobe_108",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a08/74a/thumb_117_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a08/74a/thumb_117_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/621/c9d/23b/thumb_464784_0_0_0_0_auto.webp",
                },
                {
                    id: 270,
                    name: "Gratare si cuptoare",
                    slug:
                        "gratare-gradina-moderne-modele-profesionale-pret-grill-curte_270",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a09/9d0/thumb_119_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/604/a09/9d0/thumb_119_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/621/c9d/23c/thumb_464787_0_0_0_0_auto.webp",
                },
                {
                    id: 712,
                    name: "Terasa si gradina",
                    slug: "amenajare-gradina-decoratiuni-terasa-exterior-curte_448",
                    preview_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/61d/d61/b99/thumb_331625_350_260_0_0_fit.webp",
                    mobile_image:
                        "https://www.pefoc.ro/storage/app/uploads/public/61d/d61/b99/thumb_331625_350_260_0_0_fit.webp",
                    icon:
                        "https://www.pefoc.ro/storage/app/uploads/public/620/536/1f1/thumb_463187_0_0_0_0_auto.webp",
                },
            ];
        },
    },
    methods: {
        isMobile() {
            if (window.innerWidth <= 760) {
                return true;
            } else {
                return false;
            }
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
        }, 
    },
    created() {
        //this.$store.dispatch("shop/getCategories");
        if (!this.categories.length) {
            this.$store.dispatch("shop/getBigMenu");
        }
    },
    mounted() {
        setTimeout(() => {
        var toScroll = this.getCookie('scrollToProducts');
        if(toScroll == 1) {
            var produseSection = document.getElementById("produse");
            produseSection.scrollIntoView({ behavior: "smooth", block: "start"});
            document.cookie = "scrollToProducts=0";
        }
        }, 500, this);
    }
};
</script>

<style scoped>
.homemaincategs {
    background-image: url("/themes/pefoc/assets/images/brazi_background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

@media (max-width: 576px) {
    .homemaincategs {
        background-image: url("/themes/pefoc/assets/images/brazi_background_mobile.webp");
    }
}

</style>
