<template>
    <section id="filozofie">
        <div class="container">
            <div class="row">

                <div class="filoztext">

                    <div class="col-lg-7 col-md-12 col-12"> 
                        
                        <div class="">
                            <h2 class="title">Din ’98 <br> construim o cultură a calității</h2>	
                        </div>

                    </div>

                    <div class="col-lg-9 offset-lg-2 col-12">
                        
                        <div class="descrip">

                            Inca de la inceput ne-am propus sa plasam la baza activitatii noastre un set de valori, atitudini si practici, pe care sa le urmarim intr-un mod intentionat, cu scopul de a asigura calitate superioara prin produsele si serviciile pe care le oferim.
                            Ne focusam constant pentru consolidarea unei echipe bine instruite si responsabile, iar astfel, munca noastra sa reflecte intotdeauna un lucru bine facut. Selectam cu atentie furnizorii  de seminee pe lemne, decorative, gratare sau accesorii, care pot reflecta un pret corect, deoarece noi la randul nostru, cunoastem provocarea de a realiza produse precum seminee cu standarde inalte de calitate.
                            Atentia noastra zilnica consta in identificarea si eliminarea oricarei probleme sau erori care ar putea genera o experienta neplacuta pentru client. Prin aceasta practica, am castigat o incredere crescuta in piata din Romania si Europa, plasand nevoia si dorintele consumatorului pe primul loc.
                            Eforturile depuse in ultimele doua decenii ne-au impins in acumularea unei experiente solide, indiferent ca vorbim despre o sursa alternativa de caldura, precum semineul sau un aparat de gatit, atat in domeniul rezidential, comercial sau industrial. 

                        </div>

                        <router-link to="/despre-noi" class="blog-read-more">
                            despre noi <span class="arrow"><img alt="Contact" width="165" height="14" src="@/assets/images/arrow-right-gray.svg"/></span></router-link>
                        <!-- <p class="more textCenter"><a href="/despre-noi" class="on-mobile">FILOZOFIA NOASTRĂ</a></p> -->

                    </div>

                </div>		
        
        </div>  
    </div>
    </section>
</template>

<style scoped>
#filozofie .filoztext .arrow {
  position: absolute;
  left: 170px;
}

@media (max-width: 768px) {
  #filozofie .filoztext .arrow {
    left: unset;
    right: 15px;
  }
}
</style>