<template>
    <section id="avantaje">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 avantaje-logo">
                    <!-- <h2 class="h1 responsive-text">Avantajele pentru care merita sa ne alegi</h2> -->
                    <img
                        width="173"
                        height="173"
                        loading="lazy"
                        src="./../../assets/images/googlereview.webp"
                        alt="Google review"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12 col-12">
                    <div class="vertab-text">Transport gratuit</div>
                    <p class="avantaj-text-body">
                        Asiguram o livrare rapida si gratuita oriunde in Romania la
                        produsele voluminoase: hornuri, sobe si focare seminee, pe lemne,
                        gaz, electrice sau peleti, din stocul permanent de peste 75000
                        articole.
                    </p>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12 col-12">
                    <div class="vertab-text">Calitatea garantata</div>
                    <p class="avantaj-text-body">
                        Ne angajam sa oferim servicii si produse de calitate, cu preturi
                        personalizate, nu doar pentru semineele pe lemne cu care v-am
                        obisnuit, ci si pentru toate produsele din catalog.
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12 col-12">
                    <div class="vertab-text">Garantie pana la 30 ani</div>
                    <p class="avantaj-text-body">
                        Gratie tehnologiei avansate de fabricare, dar si a duratei mari de
                        viata a produselor, oferim garantie de 5-10 ani la camere de
                        ardere, semineu si soba si chiar de 30 de ani pentru cosurile de
                        fum.
                    </p>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12 col-12">
                    <div class="vertab-text">Consultanta gratuita</div>
                    <p class="avantaj-text-body">
                        Ca afacere de familie, fiind la a doua generatie, am dobandit o
                        experienta bogata, oferind astfel sfaturile si consultanta
                        potrivita ca tu sa fii sigur ca ai facut cele mai bune alegeri.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
