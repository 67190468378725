<template>
  <section v-if="testimonials.length > 2" id="testimoniale">
    <div class="container">
      <div class="">
        <h2 class="title">
          Scopul nostru nu e să fim cei mai mari, ci cei mai buni și cei mai apreciați
        </h2>
      </div>
      <div id="reviewsCarousel" class="reviewsCarousel">
        <Carousel :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="testimonial in testimonials" :key="testimonial.id">
            <div class="item_wrp carousel_slide">
              <div class="art-det">
                <div class="quotation">                 
                  <img width="31" height="20" loading="lazy" src="./../../assets/images/quotation.svg" alt="" />
                </div>
                <div class="person">
                  {{ testimonial.name }}
                </div>
                <div class="review">
                  {{ testimonial.message }}
                </div>
                <div class="line">
                  |
                </div>
                <div class="video">
                  <a
                    target="_blank"
                    :href="testimonial.video_url"
                  >
                    vezi video
                  </a>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <navigation>
              <template #next
                >Urmatoare
                <img
                  alt="Testimoniale urmatoare"
                  width="75"
                  height="15"
                  src="@/assets/images/arrow-right-112.svg"
                />
              </template>
              <template #prev
                >Anterioare
                <img
                  alt="Testimoniale anterioare"
                  width="75"
                  height="15"
                  src="@/assets/images/arrow-left-112.svg"
                />
              </template>
            </navigation>
          </template>
        </Carousel>
      </div>

      <div class="row">
        <div id="arrow" class="col-12 col-sm-12 col-md-3 col-lg-2 arrows">
          <div class="custom-controls-nav cell"></div>
        </div>
      </div>
      <div class="circle-image">
        <img
          width="174"
          height="175"
          loading="lazy"
          src="./../../assets/images/avantaje.webp"
          alt="1998"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "start",
      wrapAround: true,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
     breakpoints: {
      // 768px and up
      768: {
        itemsToShow: 3,
        snapAlign: "start",
      },
    },
  }),
  computed: {
    testimonials() {
      return this.$store.getters["testimonials/testimonials"];
    },
  },
  mounted() {
    this.$store.dispatch("testimonials/getTestimonials");
  },
});
</script>

<style>
#testimoniale {
  position: relative;
  background-color: #1d1d1d;
  padding: 100px 0 70px 0;
}
#testimoniale .title {
  font-family: Raleway SemiBold;
  object-fit: contain;
  font-size: 74px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
  margin: auto 80px;
}
#reviewsCarousel {
  margin-top: 70px;
}
#testimoniale .carousel__prev,
#testimoniale .carousel__next {
  width: 75px !important;
  background: unset;
  text-indent: -9000px;
  /* position: relative; */
  display: inline-flex;
  /* margin-top: 60px; */
  transform: unset;
  bottom: 330px;
  top: 40%;
}

#testimoniale .carousel__prev {
  left: -280px;
}

#testimoniale .carousel__next {
  right: -280px;
}

#testimoniale .carousel__slide {
  padding: 22px;
  align-items: start;
}

#testimoniale .quotation {
  margin-bottom: 20px;
}
#testimoniale .person {
  font-family: Montserrat Bold;
  font-size: 20px;
  line-height: 1.35;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
}
#testimoniale .review {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
#testimoniale .line {
  font-size: 20px;
  color: #979797;
  margin: 10px 0;
}
#testimoniale .video > * {
  color: #fff;
}

#testimoniale .carousel__prev img,
#testimoniale .carousel__next img {
  width: 100%;
  filter: invert(0.8);
}

#testimoniale .more-btn--wrapper .arrow--wrapper img {
  float: right;
  margin-top: 9px;
}

#testimoniale .circle-image {
  text-align: center;
  position: absolute;
  bottom: -87px;
  left: calc(50% - 87px);
}

#testimoniale .circle-image img {
  position: relative;
  bottom: -50%;
}

@media (max-width: 1900px) {
  #testimoniale {
    /* padding-top: 30px;  */
  }
  #testimoniale .container .title, #testimoniale .container #reviewsCarousel .carousel__viewport, #testimoniale .carousel__prev, #testimoniale .carousel__next {
    scale: 0.7; 
  }
  #reviewsCarousel {
    margin-top: -80px;
    margin-bottom: -100px;
  }
  #testimoniale .carousel__prev {
    left: 0;
  }
  #testimoniale .carousel__next {
    right: 0;
  }
}

@media (max-width: 1200px) {
  #testimoniale .title {
    font-size: 60px;
  }
  #testimoniale .container .title, #testimoniale .container #reviewsCarousel .carousel__viewport, #testimoniale .carousel__prev, #testimoniale .carousel__next {
    scale: 0.8; 
  }
  #testimoniale .carousel__prev {
    left: -10px;
  }
  #testimoniale .carousel__next {
    right: -10px;
  }
  #reviewsCarousel {
    margin-top: -40px;
    margin-bottom: -60px;
  }
}

@media (max-width: 991px) {
  #testimoniale {
    padding-bottom: 100px;
  }
  #testimoniale .title {
    font-size: 41px;
  }
  #testimoniale .review {
    font-size: 13px;
  }
  #testimoniale .carousel__prev {
    left: -15px;
  }
  #testimoniale .carousel__next {
    right: -15px;
  }
  #reviewsCarousel {
    margin-bottom: -40px;
  }
}

@media (max-width: 768px) {
  #reviewsCarousel {
    margin: 40px 0 0 0;
  }
  #testimoniale .container .title, #testimoniale .container #reviewsCarousel .carousel__viewport, #testimoniale .carousel__prev, #testimoniale .carousel__next {
    scale: 1; 
  }
  #testimoniale {
    padding-top: 50px;
    margin-bottom: 50px;
  }
  #testimoniale .title {
    font-size: 38px;
    margin: auto;
  }
  #testimoniale .person {
    font-size: 14px;
  }
  #testimoniale .review {
    font-size: 14px;
    margin-bottom: 8px;
  }
  #testimoniale .carousel__prev, #testimoniale .carousel__next {
    position: relative;
    margin-top: 30px;
    right: unset;
    left: unset;
    bottom: unset;
  }
  #testimoniale .carousel__prev {
    margin-right: 20px;
  }
  #testimoniale .carousel__next {
    margin-left: 20px;
  }
  #testimoniale .circle-image {
    bottom: -62px;
    left: calc(50% - 62px);
  }
  #testimoniale .circle-image img {
    height: 124px;
    width: auto;
  }
  
}

</style>
