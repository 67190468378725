<template>
  <carousel></carousel>
  <shop-by-category></shop-by-category>
  <review-stars></review-stars>
  <europe-map></europe-map>
  <contact-us-home></contact-us-home>
  <reviews-home></reviews-home>
  <about-us-home></about-us-home>
  <video-feed-card></video-feed-card>
  <blog-feed></blog-feed>
  <points-view></points-view>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/blocks/Carousel.vue'
import ShopByCategory from '@/components/blocks/ShopByCategory.vue'
import AboutUsHome from '@/components/blocks/AboutUsHome.vue'
import VideoFeedCard from '@/components/blocks/VideoFeedCard.vue'
import BlogFeed from '@/components/blocks/home/BlogFeed.vue'
import EuropeMap from '@/components/blocks/EuropeMap.vue'
import ContactUsHome from '@/components/blocks/ContactUsHome.vue'
import ReviewsHome from '@/components/blocks/ReviewsHome.vue'
import PointsView from '@/components/blocks/PointsView.vue'
import ReviewStars from '@/components/blocks/ReviewStars.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
    ShopByCategory,
    AboutUsHome,
    VideoFeedCard,
    BlogFeed,
    EuropeMap,
    ContactUsHome,
    ReviewsHome,
    PointsView,
    ReviewStars,
  },

  methods: {
    goto() {
      var element = document.getElementById('produse');
      var headerOffset = 40;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    },
  },

  mounted() {

    this.$store.commit('seo/setParams', {
        seo_title: 'Semineu pe lemne la preturi mici, seminee PeFoc rustice, moderne',
        seo_description: 'La PeFoc ai o gama diversificata de seminee moderne, termoseminee, sobe pe lemne, focare seminee, usi de semineu la preturi accesibile. Vezi oferta noastra.',
        seo_keywords: 'termosemineu, semineu centrala, cu serpentina, focar, pe colt, calorifere, lemne, pardosea, incalzire, toata casa',
    });

    
    const scrollTo = this.$store.getters['scroll/scrollTo'];
    if (scrollTo) {
      this.goto();
      this.$store.dispatch('scroll/actScrollTo', false);
    }
  },

  created() {
    this.$store.dispatch('vlog/getBestVideos');
  }

}
</script>

<style scoped>

</style>
