<template>
  <div class="mentenanta">
    <div class="mentenanta_area">
      <div class="container">
        <div class="big_logo_wide">
          <router-link
            :to="{ name: 'Home' }"
            class="logo t_align_c full_width d_inline_b"
          >
            <img src="@/assets/images/logo_big.png" alt="" />
          </router-link>
        </div>
      </div>
      <div class="container">
        <div class="">
          <!--products-->
          <div class="pagenot relative full_width">
            <h3>
              Momentan site-ul este in mentenanta. <br />
              Revenim curand!
            </h3>
            <div class="pagenot-text">
              <!-- <h3 class="fill">Oops... Pagina nu exista!</h3> -->
            </div>
            <h3>
              Pentru urgente contactati-ne la nr: <br />
              0377.101.500 !
            </h3>
          </div>
        </div>
      </div>
      <div class="copyright">All right reserved &copy; PeFoc.ro V3</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>

<style scoped>
/*mentenanta*/
.mentenanta {
  background-color: #990000;
}
.mentenanta_area h3 {
  color: #fff;
  font-size: 46px;
  font-weight: normal;
  padding: 0;
  margin: 100px auto;
}
.mentenanta_area .copyright {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #900;
}

.mentenanta_area div.relative {
  margin-top: 0;
}
.mentenanta_area h3 {
  margin: 70px 20px;
  font-size: 35px;
}

.mentenanta {
  text-align: center;
}

@media (max-width: 568px) {
  .mentenanta_area .logo img {
    width: 250px;
  }
  .mentenanta_area h3 {
    margin: 70px 20px;
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .mentenanta_area h3 {
    margin: 50px 20px;
    font-size: 25px;
  }
  .mentenanta_area .logo img {
    width: 250px;
  }
  .mentenanta_area .copyright {
    position: initial;
  }
}
@media (min-width: 641px) and (max-width: 1367px) {
  .mentenanta_area h3 {
    margin: 70px 20px;
    font-size: 37px;
  }
  .mentenanta_area .logo img {
    width: 400px;
  }
  .mentenanta_area .copyright {
    position: initial;
  }
}
@media (min-width: 1338px) {
  .mentenanta_area {
    height: 100vh;
    /*		    min-height: 947px;
*/
  }
}
</style>
