<template>
  <!-- {% if arImageList is not empty %} -->
  <section class="carousel">
    <div class="container" >
      <h1 class="carousel-big-title">
        Poveste începută în '98 <br />
        de la un șemineu pe lemne…
      </h1>
    </div>
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <!-- {% for obImage in arImageList %} -->
        <slide-carousel
          v-for="(slide, index) in slides"
          :key="index"
          :slide="slide"
          :class="index === 0 ? 'active' : ''"
        ></slide-carousel>
        <!-- {% endfor %} -->
      </div>

      <div class="wrp-slogan">
        <div class="container">
          <div class="wrp-controlers">
            <ol class="carousel-indicators pf-carousel-indicators">
              <li
                v-for="n in 4"
                :key="n"
                data-target="#carouselExampleIndicators"
                :data-slide-to="n - 1"
                :class="n === 1 ? 'active' : ''"
              ></li>
            </ol>
          </div>
        </div>
      </div>

      <!--  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a> -->
    </div>
  </section>
  <!-- {% endif %} -->
</template>

<script>
import SlideCarousel from "@/components/blocks/SlideCarousel.vue";
export default {
  name: "Carousel",
  components: {
    SlideCarousel,
  },
  data() {
    return {
      slides: [
        {
          src: "Pefoc_4.webp",
          mobile_src: "slider1_mobile.webp",
        },
        {
          src: "slider2.webp",
          mobile_src: "slider2_mobile.webp",
        },
        {
          src: "slider.webp",
          mobile_src: "slider3_mobile.webp",
        },
        {
          src: "Pefoc_5.webp",
          mobile_src: "slider4_mobile.webp",
        },
      ],
    };
  },
};
</script>

<style scoped>
.wrp-slogan {
  bottom: 10px;
  position: absolute;
  width: 100%;
}

.wrp-controlers {
  margin: 0;
  padding-left: 30px;
  padding-top: 30px;
}

.wrp-slogan .wrp-controlers {
  padding-left: 0;
}

.pf-carousel-indicators {
  margin-left: unset;
  margin-right: unset;
  position: relative;
  justify-content: left;
  margin-bottom: 60px;
  align-items: center;
}
.pf-carousel-indicators li {
  display: inline-block;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 0 20px 0 0;
  background-color: #fff;
  width: 2.9px;
  height: 2.9px;
}

ol.carousel-indicators.pf-carousel-indicators li.active {
  height: 6px;
  width: 6px;
}

.carousel-big-title {
  position: absolute;
  bottom: 125px;
  z-index: 1;
  text-align: left;
  font-size: 74px;
  font-family: Raleway Thin;
  line-height: 1;
  margin-bottom: 40px;
  color: #fff;
	-webkit-text-stroke: 0.6px #fff;
}

@media (max-width: 1200px) {
  .carousel-big-title {
    font-size: 60px;
    bottom: 100px;
  }
}

@media (max-width: 991px) {
  .carousel-big-title {
    font-size: 40px;
    bottom: 140px;
  }
}

@media (max-width: 768px) {
  .pf-carousel-indicators {
    justify-content: center;
    margin-bottom: 15px;
  }

  .pf-carousel-indicators li {
    height: 6px;
    width: 6px;
  }
  ol.carousel-indicators.pf-carousel-indicators li.active {
    height: 9px;
    width: 9px;
  }

  .carousel-big-title {
    text-align: center;
    margin: auto;
    bottom: 90px;
  }
}

@media (max-width: 576px) {
  .pf-carousel-indicators {
    justify-content: left;
  }

  .carousel-big-title {
    font-size: 27px;
    text-align: left;
    bottom: 16vw;
  }
}
</style>
