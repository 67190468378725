<template>
  <section id="reviewstars">
    <div class="container">
      <div class="rating mb-5">
        <div class="title">
          4,9
        </div>
        <div class="verified-wrapper">
          <div class="verified-text">Verified by Google</div>
          <img width="230" height="40" loading="lazy" src="./../../assets/images/stars.webp" alt="Google Stars" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 subtitle">
          Evaluările clienţilor
        </div>
        <div class="col-sm-10 description">
          Transparența părerilor și notele oferite de către clienții noștri, te pot ajuta și pe tine să alegi cu încredere produsele și serviciile noastre.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }, 
  },
  mounted() {
    setTimeout(() => {
      var toScroll = this.getCookie('scrollToReview');
      if(toScroll == 1) {
        var reviewstars = document.getElementById("reviewstars");
        reviewstars.scrollIntoView({ behavior: "smooth", block: "center"});
        document.cookie = "scrollToReview=0";
      }
    }, 500, this);
  }

}

</script>

<style>
#reviewstars {
  margin-top: 100px;
  background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, #f2f2f2 63%);
  padding-bottom: 145px;
}

#reviewstars .title {
  display: inline-block;
  font-family: Raleway Semibold;
  line-height: 1;
  font-size: 130px;
  padding-right: 35px;
}

#reviewstars .subtitle {
  font-family: Raleway SemiBold;
  font-size: 54px;
  line-height: 1.41;
  margin-bottom: 10px;
  line-height: 1.07;
}
#reviewstars .description {
  font-size: 24px;
  font-weight: normal;
  color: #2c2c2d;
  line-height: 1.42;
}

#reviewstars .verified-wrapper {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  padding-top: 40px;
}

#reviewstars .verified-text {
  display: inline-block;
  font-family: Montserrat;
  font-size: 18px;
  line-height: 1.78;
  color: #5a5a5a;
  position: absolute;
  bottom: 45px;
}

@media (max-width: 991px) {
  #reviewstars {
    padding-bottom: 90px;
  }
}

@media (max-width: 768px) {
  #reviewstars {
    margin-top: 30px;
    padding-bottom: 60px;
  }

  #reviewstars .title {
    width: 100%;
    font-size: 90px;
  }

  #reviewstars .description {
    font-size: 16px;
  }

  #reviewstars .verified-text { 
    font-size: 16px;
  }

  #reviewstars .subtitle {
    margin-bottom: 30px;
  }
}

</style>
