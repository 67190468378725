<template>
    <div class="col-md-4 col-sm-6 col-6 home-menu-col">
        <router-link :to="{ name: 'ProductList', params: { slug: slug } }">
            <div class="">
                <img
                    itemprop="image"
                    :src="image"
                    class="home-menu-img"
                    :alt="name"
                    width="165"
                    height="116"
                />
                <div class="item-title">
                    <span>{{ name }}</span>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: ["id", "name", "image", "slug"],
};
</script>

<style scoped>
.home-menu-img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    transition: transform.2s;
    transform: scale(0.95);
}

.home-menu-img:hover {
    transition: transform.2s;
    transform: scale(1);
}

.home-menu-col {
    padding-top: 30px;
}

.home-menu-col > a {
    text-decoration: none;
}

.home-menu-wrp .item-title {
    font-family: Raleway SemiBold;
    font-size: 28px;
    height: 110px;
    line-height: normal;
    color: #2e2e30;
    text-align: center;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    width: 300px;
    margin: auto;
}

@media (max-width: 1200px) {
    .home-menu-img {
        height: 210px;
    }

    .home-menu-wrp .item-title {
        font-size: 26px;
        width: auto;
    }
}

@media (max-width: 991px) {
    .home-menu-img {
        height: auto;
    }

    .home-menu-wrp .item-title {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .home-menu-wrp .item-title {
        font-size: 24px;
    }
}

@media (max-width: 576px) {
    .home-menu-col:nth-of-type(2n+1) {
        padding-right: 0;
    }

    .home-menu-col:nth-of-type(2n) {
        padding-left: 0;
    }

    .home-menu-img {
        height: 34vw;
        width: auto;
    }

    .home-menu-wrp .item-title {
        font-size: 18px;
        height: 70px;
        padding: 15px 0;
        width: 165px;
        margin: auto;
    }
}
</style>
