<template>
  <div class="carousel-item">
    <img
      class="d-block w-100 carousel-item-img"
      :src="
        windowWidth > 768
          ? require(`@/assets/images/slider/${slide.src}`)
          : require(`@/assets/images/slider/${slide.mobile_src}`)
      "
      alt="Image Title"
      loading="lazy"
    />

    <!-- <div class="carousel-caption container">
      <h1 class="mb-3 carousel-item__title">
        Poveste începută în '98 <br />
        de la un șemineu pe lemne…
      </h1>
      <p class="d-none d-md-block"></p> -->
      <!-- {% if obImage.link is not empty %} -->
      <!-- <a class="btn btn-dark" href="{{ obImage.link }}" role="button">{{ obImage.link_name }}</a> -->
      <!-- {% endif %} -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: ["slide"],
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    document
      .getElementsByClassName("carousel-item-img")
      .item(0)
      .removeAttribute("loading");
  },
};
</script>

<style scoped>
.carousel-item {
  height: 660px;
}

.carousel-caption {
  bottom: 0px;
  top: unset;
  left: 0;
  right: 0;
  z-index: 0;
}

.carousel-item__title {
  text-align: left;
  font-size: 74px;
  font-family: Raleway Thin;
  line-height: 1;
  margin-bottom: 40px;
  width: 90%;
  color: #fff;
	-webkit-text-stroke: 0.6px #fff;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .carousel-item__title {
    font-size: 60px;
  }

  .carousel-item {
    height: 460px;
  }
}

@media (max-width: 991px) {
  .carousel-item__title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .carousel-caption {
    top: 50%;
    bottom: unset;
    z-index: 100;
  }

  .carousel-item__title {
    text-align: center;
    width: 90%;
    margin: auto;
  }

  .carousel-item:after {
    /* content:'\A';
        position:absolute;
        width:100%; height:100%;
        top:0; left:0;
        background:rgba(0,0,0,0.5);
        opacity:1; */
  }

  .carousel-item {
    height: auto;
    margin-top: 100px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .carousel-caption h5 {
    text-align: center;
    font-size: 45px;
    line-height: 1;
  }
}

@media (max-width: 576px) {
  .carousel-item__title {
    width: 100%;
    font-size: 27px;
    text-align: left;
  }

  .carousel-item {
    height: 260px;
    height: auto;
    margin-top: 60px;
  }
}
</style>
