<template>
  <section id="slideechipa">
    <div class="container">
      <div class="row contact-line">
        <div class="col-12 col-lg-6">
          <div class="">
            <h2 class="title">
              25 ani <br />
              de incredere
            </h2>
          </div>

          <div class="descrip">
              Viziunea noastra incepe in urma cu doua decenii, cand vanzarea online a unui
              semineu pe lemne era greu de imaginat. In prezent, distribuim o gama mult
              mai larga, nu doar semineuri. Totusi, pasiunea noastra de la inceput,
              semineul pe lemne, de unde am crescut si am evoluat, este punctul forte si
              astazi. Bazati pe experienta acumulata in peste 20 de ani de activitate,
              concentrarea noastra se indreapta asupra produselor, imbinand partea de
              siguranta si functionalitate cu designul deosebit, indiferent ca vorbim de
              un semineu pe lemne, gaz, electric, peleti sau bioetanol, modele ieftine sau
              premium.. In continuare, punem clientul pe primul loc, oferind incredere si
              siguranta, pastrand aceleasi valori!
          </div>

          <router-link to="/contact" class="blog-read-more"
            >contactează-ne <span class="arrow"><img alt="Contact" width="165" height="14" src="@/assets/images/arrow-right-gray.svg"/></span></router-link
          >
        </div>
        <div class="d-none d-lg-block col-md-6 right-col">
          <img class="contact-image" width="540" height="416" loading="lazy" src="./../../assets/images/contacteaza.webp" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
p {
  line-height: 1.7;
  font-size: 14px;
}
#slideechipa .contact-line .arrow {
  position: absolute;
  left: 220px;
}
@media (max-width: 1200px) {
  #slideechipa .contact-line .contact-image img {
    width: 450px;
    height: auto;
  }
}
@media (max-width: 768px) {
  #slideechipa .contact-line .arrow {
    left: unset;
    right: 15px;
  }
}
@media (max-width: 576px) {
  #slideechipa .contact-line .arrow img {
    width: 125px;
  }
}
</style>
